<template>
  <div>
    <div class="row text-center" v-if="$checkRoles('homeNumberingVeiw')">
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-3">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-link"></i><span>العقود</span>
          </div>
          <div class="overview-box-count">{{ contractsCount }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-4">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-id-card"></i><span>العملاء</span>
          </div>
          <div class="overview-box-count">{{ clientsCount }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-1">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-book"></i><span>اجمالى العقود</span>
          </div>
          <div class="overview-box-count">
            {{ contractsTotalCount.toFixed(2) }}
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-2">
          <div class="overview-box-title">
            <i class="pi pi-tags"></i><span>اجمالي التحصيلات</span>
          </div>
          <div class="overview-box-count">{{ paymentsCount.toFixed(2) }}</div>
        </div>
      </div>
    </div>
    <div class="mt-5 row">
      <div class="col-md-1" v-if="$checkRoles('receivingInventoryVeiw')">
        <img
          src="../../assets/icons/1.png"
          alt=""
          width="70%"
          v-tooltip="'استلام مخزني'"
          @click="$router.push('/admin/inventory/receivingInventory')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('welcomeVeiw')">
        <img
          src="../../assets/icons/2.png"
          alt=""
          width="70%"
          v-tooltip="'اغلاق العقود'"
          @click="$router.push('/admin/secretary/welcome')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('collectionVeiw')">
        <img
          src="../../assets/icons/3.png"
          alt=""
          width="70%"
          v-tooltip="'الحسابات'"
          @click="$router.push('/admin/sales/collection')"
        />
      </div>

      <div class="col-md-1" v-if="$checkRoles('contractsVeiw')">
        <img
          src="../../assets/icons/4.png"
          alt=""
          width="70%"
          v-tooltip="'العقود'"
          @click="$router.push('/admin/sales/contracts')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('materialsVeiw')">
        <img
          src="../../assets/icons/5.png"
          alt=""
          width="70%"
          v-tooltip="'المخازن'"
          @click="$router.push('/admin/sales/materials')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('installationsVeiw')">
        <img
          src="../../assets/icons/6.png"
          alt=""
          width="70%"
          v-tooltip="'اوامر التركيب'"
          @click="$router.push('/admin/technicalSupport/installations')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('operationsVeiw')">
        <img
          src="../../assets/icons/7.png"
          alt=""
          width="70%"
          v-tooltip="'اوامر التشغيل'"
          @click="$router.push('/admin/technicalSupport/operations')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('deliveryVeiw')">
        <img
          src="../../assets/icons/8.png"
          alt=""
          width="70%"
          v-tooltip="'تسليم العملاء'"
          @click="$router.push('/admin/technicalSupport/delivery')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('clientsVeiw')">
        <img
          src="../../assets/icons/9.png"
          alt=""
          width="70%"
          v-tooltip="'تعريف العملاء'"
          @click="$router.push('/admin/users/clients')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('tasksOrderDoneVeiw')">
        <img
          src="../../assets/icons/10.png"
          alt=""
          width="70%"
          v-tooltip="'تنفيذ مهام العمل'"
          @click="$router.push('/admin/sales/tasksOrderDone')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('distributionsVeiw')">
        <img
          src="../../assets/icons/11.png"
          alt=""
          width="70%"
          v-tooltip="'توزيع اوامر التركيب'"
          @click="$router.push('/admin/technicalSupport/distributions')"
        />
      </div>

      <div class="col-md-1" v-if="$checkRoles('inventoryStoreCountingVeiw')">
        <img
          src="../../assets/icons/12.png"
          alt=""
          width="70%"
          v-tooltip="'صرف مخزني'"
          @click="$router.push('/admin/inventory/inventoryStoreCounting')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('purchasesVeiw')">
        <img
          src="../../assets/icons/13.png"
          alt=""
          width="70%"
          v-tooltip="'طلب شراء'"
          @click="$router.push('/admin/sales/purchases')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('inventoryStoreCountingReqVeiw')">
        <img
          src="../../assets/icons/14.png"
          alt=""
          width="70%"
          v-tooltip="'طلب صرف مخزنى'"
          @click="$router.push('/admin/inventory/inventoryStoreCountingReq')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('tasksVeiw')">
        <img
          src="../../assets/icons/15.png"
          alt=""
          width="70%"
          v-tooltip="'متابعة العملاء المستهدفين'"
          @click="$router.push('/admin/sales/tasks')"
        />
      </div>

      <div class="col-md-1" v-if="$checkRoles('revisionsVeiw')">
        <img
          src="../../assets/icons/16.png"
          alt=""
          width="70%"
          v-tooltip="'مراجعة اوامر التشغيل'"
          @click="$router.push('/admin/technicalSupport/revisions')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('deliveryNotesVeiw')">
        <img
          src="../../assets/icons/17.png"
          alt=""
          width="70%"
          v-tooltip="'ملاحظات استلام العملاء'"
          @click="$router.push('/admin/technicalSupport/deliveryNotes')"
        />
      </div>
      <div class="col-md-1" v-if="$checkRoles('tasksOrderVeiw')">
        <img
          src="../../assets/icons/18.png"
          alt=""
          width="70%"
          v-tooltip="'مهام العمل'"
          @click="$router.push('/admin/sales/tasksOrder')"
        />
      </div>
    </div>

    <div class="mt-5 row">
      <div class="col-md-10">
        <marquee
          onmouseover="this.stop();"
          onmouseout="this.start();"
          direction="right"
          behavior="scroll"
        >
          <template v-for="x of notifications">
            {{ x.name }} #{{ x.code }} #{{ x.clientName }}
            <span style="color: #007bff;" :key="x"> | </span>
          </template>
        </marquee>
      </div>
      <div class="col-md-2">
        <Dropdown v-model="year" :options="yearList" />
      </div>
    </div>
    <div class="row mt-5" v-if="show">
      <div class="col-md-4">
        <Chart
          type="doughnut"
          v-if="$checkRoles('homedoutVeiw')"
          :data="chartData"
        />
      </div>
      <div class="col-md-8">
        <Chart
          type="bar"
          v-if="$checkRoles('homeLineVeiw')"
          :data="basicData"
        />
      </div>
      <div class="col-md-12">
        <Chart
          type="line"
          v-if="$checkRoles('homePriceVeiw')"
          :data="chartDataAll"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let today = new Date();
    let year = today.getFullYear();
    return {
      year,
      yearList: [
        2021,
        2022,
        2023,
        2024,
        2025,
        2026,
        2027,
        2028,
        2029,
        2030,
        2031,
        2032,
        2033,
        2034,
        2035,
        2036,
        2037,
        2038,
        2039,
        2040,
      ],
      contractsCount: 0,
      clientsCount: 0,
      contractsTotalCount: 0,
      paymentsCount: 0,

      chartData: {
        labels: ['اجمالى العقود', 'المحصل', 'المتبقي'],
        datasets: [
          {
            data: [300, 50, 0],
            backgroundColor: ['#48dbfb', '#1dd1a1', '#ff6b6b'],
            hoverBackgroundColor: ['#48dbfb', '#1dd1a1', '#ff6b6b'],
          },
        ],
      },
      basicData: {
        labels: [
          'يناير',
          'فبراير',
          'مارس',
          'أبريل',
          'مايو',
          'يونيو',
          'يوليو',
          'أغسطس',
          'سبتمبر',
          'أكتوبر',
          'نوفمبر',
          'ديسمبر',
        ],

        datasets: [
          {
            label: 'العقود',
            data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56],
            fill: false,
            backgroundColor: '#42A5F5',
            tension: 0.4,
          },
          {
            label: 'عملاء',
            data: [28, 48, 40, 19, 86, 27, 90, 81, 56, 55, 40, 65],
            fill: false,
            backgroundColor: '#FFA726',
            tension: 0.4,
          },
        ],
      },
      show: false,
      chartDataAll: {
        labels: [
          'يناير',
          'فبراير',
          'مارس',
          'أبريل',
          'مايو',
          'يونيو',
          'يوليو',
          'أغسطس',
          'سبتمبر',
          'أكتوبر',
          'نوفمبر',
          'ديسمبر',
        ],

        datasets: [
          {
            fill: false,
            label: 'اجمالى العقود',
            borderColor: '#48dbfb',
            tension: 0.4,
            data: [],
          },
          {
            fill: false,

            label: 'المحصل',
            borderColor: '#1dd1a1',
            data: [],
            tension: 0.4,
          },
          {
            fill: false,

            label: 'المتبقى',
            borderColor: '#ff6b6b',
            data: [],
            tension: 0.4,
          },
        ],
      },
    };
  },

  methods: {
    async getData() {
      const contracts = await this.$http.post(`contracts/count`, {
        year: this.year,
      });

      this.contractsCount = await contracts.data;

      const clients = await this.$http.post(`clients/count`, {
        year: this.year,
      });
      this.clientsCount = clients.data;

      const contractsTotal = await this.$http.get(
        `contracts/contractsTotalCount?year=${this.year}`,
      );

      this.contractsTotalCount = await contractsTotal.data.reduce(
        (val, item) => {
          return val + item.total;
        },
        0,
      );
      const payments = await this.$http.get(
        `payments/countNotNull?year=${this.year}`,
      );

      this.paymentsCount = await payments.data.reduce((val, item) => {
        return val + item.price;
      }, 0);

      this.chartData.datasets[0].data = await [
        this.contractsTotalCount,
        this.paymentsCount,
        this.contractsTotalCount - this.paymentsCount,
      ];

      const contractsTotalCountMonth = await this.$http.get(
        `contracts/contractsTotalCountMonth?year=${this.year}`,
      );

      this.basicData.datasets[0].data = await contractsTotalCountMonth.data;

      const clientsTotalCountMonth = await this.$http.get(
        `clients/clientsTotalCountMonth?year=${this.year}`,
      );

      this.basicData.datasets[1].data = await clientsTotalCountMonth.data;

      const contractsTotalCountByMonth = await this.$http.get(
        `contracts/contractsTotalCountByMonth?year=${this.year}`,
      );

      this.chartDataAll.datasets[0].data = await contractsTotalCountByMonth.data;

      const contractsPayCountByMonth = await this.$http.get(
        `contracts/contractsPayCountByMonth?year=${this.year}`,
      );

      this.chartDataAll.datasets[1].data = await contractsPayCountByMonth.data;
      const cl = [];
      let scl = 0;
      for (const item of contractsTotalCountByMonth.data) {
        await cl.push(item - contractsPayCountByMonth.data[scl]);
        scl = scl + 1;
      }
      this.chartDataAll.datasets[2].data = await cl;

      this.show = await true;
    },
  },
  async created() {
    this.getData();
    const user = JSON.parse(localStorage.admin);
    this.$http.get(`notifications/getByUser/` + user.id).then((response) => {
      this.notifications = response.data;
    });
  },
  watch: {
    year(val) {
      this.show = false;
      this.getData();
    },
  },
};
</script>

<style>
.overview-box {
  padding: 15px;
  color: #ffffff;
  min-height: 100px;
  border-radius: 4px;
  margin: 0 !important;
}
.overview-box .overview-box-title {
  font-weight: bold;
  width: 100%;
}
.overview-box .overview-box-title i {
  vertical-align: middle;
  font-size: 20px;
}
.overview-box .overview-box-title span {
  margin-right: 0.5em;
  vertical-align: middle;
}
.overview-box .overview-box-count {
  color: #ffffff;
  font-size: 24px;
  width: 100%;
  display: block;
  padding: 5px 0;
}
.overview-box.overview-box-1 {
  background-color: #007bff;
  border: solid 1px #007bff;
  color: #ffffff;
}
.overview-box.overview-box-2 {
  background-color: #28a745;
  border: solid 1px #28a745;
  color: #ffffff;
}
.overview-box.overview-box-3 {
  background-color: #fd7e14;
  border: solid 1px #fd7e14;
  color: #ffffff;
}
.overview-box.overview-box-4 {
  background-color: #6f42c1;
  border: solid 1px #6f42c1;
  color: #ffffff;
}
</style>
